import * as React from 'react'
import { TopicEnum } from '@api/endpoints/forms'
import { VehicleMinimalInfo } from '@stores/forms'
import styles from './VehicleFormSuccess.module.css'
import { useTranslation } from 'react-i18next'
import { DiscountVehiclesDesktop } from '@layout/DiscountVehicles'
import pageSectionStyles from '@layout/PageSection/PageSection.module.css'
import { useLocation } from 'react-router-dom'


export type VehicleFormSuccessProps = Readonly<{
  topic: TopicEnum
  data: VehicleMinimalInfo
  onReset: () => void
  showContacts?: boolean
}>

export const VehicleFormSuccess: React.FC<VehicleFormSuccessProps> = ({ onReset }) => {
  const { t } = useTranslation()
  const location = useLocation()

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      const params = new URLSearchParams(location.search)
      if (!params.has('finalStep')) {
        onReset()
      }
    }, 500)



    return () => clearTimeout(timeoutId) // Clean up the timeout if the component unmounts
  }, [location, onReset])

  return (
    <>
      <div className={styles.success__container}>
        <span> {t('global.forms.vehicleRegisterSearch.messages.successMessage')}</span>
      </div>
      <div className={styles.message__container}>
        <span> {t('pages.buyback.offerMessage')}</span>
      </div>
      <div className={styles.cars__section}>
        <DiscountVehiclesDesktop buttonsProps={{ className: pageSectionStyles.ignoreExceptions }} />
      </div>
    </>
  )
}
