import * as React from 'react'
import clsx from 'clsx'
import styles from './BuyersContacts.module.css'
import { useTranslation } from 'react-i18next'
import { PersonCard, PersonCardProps } from '@layout/PersonCardNew'
import { CheckedText } from '@layout/CheckedText'
import ImgAlan from '@assets/images/team/alanSmaller.png'
import ImgRoland from '@assets/images/team/rolandSmaller.png'

export const BuyersContacts: React.FC = () => {
  const { t } = useTranslation()

  const buyers: ReadonlyArray<Omit<PersonCardProps, 'className'>> = React.useMemo(
    () => [
      {
        title: 'Roland Bugai',
        image: ImgRoland,
        phoneNumber: '+372 56 10 32 23',
        emailAddress: 'roland.bugai@carprof.ee',
        telegramContact: '+37256103223',
        position: t('components.buyers.position')
      },
      {
        title: 'Alan Kuristik',
        image: ImgAlan,
        phoneNumber: '+372 56 90 90 91',
        emailAddress: 'alan.kuristik@carprof.ee',
        telegramContact: '+37256909091',
        position: t('components.buyers.position')
      }
    ],
    [t]
  )

  return (
    <>
    <div className={styles.buyers__container}>
      <h3 className={styles.buyers__title}>{t('components.buyers.title')}</h3>
      <div className={styles.buyers__list}>
        {buyers.map((buyer, index) => (
          <PersonCard key={`buyer-${index}`} className={styles.buyer} {...buyer} />
        ))}
      </div>
    </div>
    <div className={clsx(styles.features__container, 'featureList')}>
        <CheckedText>{t('pages.buyback.advantages.fast')}</CheckedText>
        <CheckedText>{t('pages.buyback.advantages.eficient')}</CheckedText>
        <CheckedText>{t('pages.buyback.advantages.tradeIn')}</CheckedText>
    </div>
    </>
  )
}
