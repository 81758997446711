import * as React from 'react'
import { buildTelegramLink } from '@constants/links'
import SvgTelegramColoredImg from '@assets/icons/social/telegram.svg'
import { IconLink, IconLinkProps } from '@controls/buttons/IconLink'

export type LinkTelegramProps = Readonly<{
  telegramContact: string
  colored?: boolean
  size?: IconLinkProps['size']
}>

export const LinkTelegram: React.FC<LinkTelegramProps> = ({ telegramContact, colored, size }) => (
  <IconLink
    //invalid link
    href={buildTelegramLink(telegramContact)}
    target={'_blank'}
    title={telegramContact}
    src={colored ? SvgTelegramColoredImg : SvgTelegramColoredImg}
    alt={telegramContact}
    size={size}
    color={colored && size == 'square' ? 'blue' : 'blank'}
  />
)
