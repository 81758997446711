import * as React from 'react'
import { buildPhoneLink } from '@constants/links'
import SvgPhoneColoredImg from '@assets/icons/social/phone.svg'
import { IconLink, IconLinkProps } from '@controls/buttons/IconLink'

export type LinkPhoneButtonProps = Readonly<{
  phoneNumber: string
  colored?: boolean
  size?: IconLinkProps['size']
}>

export const LinkPhoneButton: React.FC<LinkPhoneButtonProps> = ({ phoneNumber, colored, size }) => (
  <IconLink
    //invalid link
    href={buildPhoneLink(phoneNumber)}
    title={phoneNumber}
    src={colored ? SvgPhoneColoredImg : SvgPhoneColoredImg}
    alt={phoneNumber}
    size={size}
    color="blank"
  />
)
