import * as React from 'react'
import { buildEmailLink } from '@constants/links'
import { EmailAddress } from '@digital-magic/zod-common-schemas'
import SvgEmailColoredImg from '@assets/icons/social/email.svg'
import SvgEmailBWImg from '@assets/icons/social/email.svg'
import { IconLink, IconLinkProps } from '@controls/buttons/IconLink'

export type LinkEmailContactProps = Readonly<{
  emailAddress: EmailAddress
  colored?: boolean
  size?: IconLinkProps['size']
}>

export const LinkEmailContact: React.FC<LinkEmailContactProps> = ({ emailAddress, colored, size }) => (
  <IconLink
    //invalid link
    href={buildEmailLink(emailAddress)}
    title={emailAddress}
    src={colored ? SvgEmailColoredImg : SvgEmailBWImg}
    alt={emailAddress}
    size={size}
    color="blank"
  />
)
