import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './Map.module.css'
import { buildPhoneLink } from '@constants/links'
import { Link } from '@mui/material'

type MapProps = Readonly<{
  showBox?: boolean
}>

export const Map: React.FC<MapProps> = ({ showBox = true }) => {
  const { t } = useTranslation()
  const phoneNumber: string = '+37259080808'
  const location = t('controls.map.location')
  return (
    <div className={styles.map}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d717.403336083508!2d24.83664478890957!3d59.431022193897135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!3m2!1sen!2sus!4v1719826477898!5m2!1sen!2sus"
        width="100%"
        height="100%"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className={styles.map__frame}
      ></iframe>

      {showBox && (
        <div className={styles.map__legend}>
          <div className={styles.map__box}>
            <p className={styles.map__title}>{t('controls.map.title')}</p>
            <p className={styles.map__text}>
              <strong>{t('controls.map.location')}</strong>
            </p>
            <p className={styles.map__text}>
              <Trans i18nKey="controls.map.workingHours" components={{ 1: <strong /> }} />
            </p>
            <p className={styles.map__text}>
              <Link href={buildPhoneLink(phoneNumber)} title={location}>
                <Trans i18nKey="controls.map.phone" components={{ 1: <strong /> }} />
              </Link>
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
