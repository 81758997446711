import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { Page } from '@layout/Page'
import { PageContainerWithHead } from '@layout/Page'
import { BuyoutWizard } from './BuyoutWizard'
import styles from './BuyoutPage.module.css'
import { Map } from '@controls/Map'

export const BuyoutPage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.buyback.title') }],
    [t, routes]
  )

  return (
    <Page id={pageId} disableScrollToTop>
      <PageContainerWithHead breadCrumbs={breadCrumbs} showBreadCrumbs={false} className={styles.noBottomPadding}>
        <div className={styles.buyoutPage}>
          <BuyoutWizard />
        </div>
      </PageContainerWithHead>
      <Map/>
    </Page>
  )
}
