import * as React from 'react'
import clsx from 'clsx'
import { EmailAddress } from '@digital-magic/zod-common-schemas'
import { PhoneNumber } from '@api/endpoints'
import { LinkEmailContact, LinkViber, LinkWhatsapp, LinkTelegram, LinkPhoneButton } from '@controls/buttons'
import styles from './PersonCard.module.css'

export type PersonCardProps = Readonly<{
  title: string
  image: HTMLImageElement['src']
  phoneNumber: PhoneNumber
  emailAddress: EmailAddress
  telegramContact: PhoneNumber
  position: string
  className?: string
}>

export const PersonCard: React.FC<PersonCardProps> = ({
  title,
  image,
  phoneNumber,
  emailAddress,
  telegramContact,
  position,
  className
}) => (
  <div className={clsx(styles.person__container, className)}>
    <div className={styles.person__avatar}>
      <img src={image} alt={title} className={styles.person__image} />
    </div>
    <div className={styles.person__details}>
      <p className={styles.person__title}>{title}</p>
      <div className={styles.person__position}>{position}</div>
      <div className={styles.person__contacts}>
        <div className={styles.person__mainContacts}>
          <LinkPhoneButton phoneNumber={phoneNumber} size="link" colored />
          <LinkEmailContact emailAddress={emailAddress} size="link" colored />
        </div>
        <div className={styles.person__icons}>
          <LinkWhatsapp phoneNumber={phoneNumber} size="square" colored />
          <LinkViber phoneNumber={phoneNumber} size="square" colored />
          <LinkTelegram telegramContact={telegramContact} size="square" colored />
        </div>
      </div>
    </div>
  </div>
)
